import { Component, ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './services/auth';
 
const routes: Routes = [
 // { path: 'login', loadChildren: './login/login.module#LoginModule' },
  { path: '', loadChildren: './coupon-validation/coupon-validation.module#CouponValidationModule' },  
  { path: 'authentication/:status', loadChildren: './coupon-success/coupon-success.module#CouponSuccessModule' }, 
  // { path: ':url/:couponCode', loadChildren: './coupon-scan/coupon-scan.module#CouponScanModule' }, 
  { path: ':couponCode', loadChildren: './coupon-scan/coupon-scan.module#CouponScanModule' }, 
  { path: 'admin', loadChildren: './layout/layout.module#LayoutModule', canActivate:[AuthGuard]},
  // { path: 'forgotpassword', loadChildren: './forgotpassword/forgotpassword.module#ForgotpasswordModule' },
  // { path: 'coupon/scan', loadChildren: './coupon-scan/coupon-scan.module#CouponScanModule' },
  { path: 'coupon/verify/:couponCode', loadChildren: './coupon-verifying/coupon-verifying.module#CouponVerifyingModule' },
 // { path: 'forgotPassword/check', loadChildren: './forgotpassword/forgotpassword.module#ForgotpasswordModule' },
  // { path: ':couponCode', loadChildren: './coupon-verifying/coupon-verifying.module#CouponVerifyingModule' }, 
  { path: 'validateotp/check', loadChildren: './validateotp/validateotp.module#ValidateotpModule' },
 // { path: 'resetpassword/check', loadChildren: './resetpassword/resetpassword.module#ResetpasswordModule' },
  { path:'download/check',loadChildren: './download-screen/download-screen.module#DownloadScreenModule' },
  { path:'otp/check',loadChildren: './otp-screen/otp-screen.module#OtpScreenModule' },
  { path: '**', redirectTo: '', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingComponent { }






