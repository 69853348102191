// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseURL: 'http://192.168.70.4:8080/Suntronix/',
  // excelURL: "http://192.168.70.4/",
  // btoaUsername: "pays",
  // btoaPassword: "pays",
  // companyid : 30,
  // flag: null

  // baseURL: 'http://54.87.202.44:8880/Pays/',
  // excelURL : "http://54.87.202.44/",
  // btoaUsername:"pays",
  // btoaPassword:"pays",
  // companyid : 30,
  // flag: null

  
  //  baseURL: 'http://app.pays.net.in:8880/Pays/',
  //  excelURL : "http://app.pays.net.in/",
  //  btoaUsername:"pays",
  //  btoaPassword:"pays" ,
  //  companyid : 27,
  //  flag: null







  // baseURL: '//192.168.70.4:8080/Suntronix/',
  // excelURL: "//192.168.70.4/",
  // btoaUsername: "pays",
  // btoaPassword: "pays", 
  // companyid : 31,
  // flag: null

  // baseURL: '//54.87.202.44:8880/Pays/', 
  // excelURL : "//54.87.202.44/",
  // btoaUsername:"pays",
  // btoaPassword:"pays",
  // companyid : 17,  
  // flag: null 

   baseURL: 'https://app.pays.net.in:8444/Pays/',
   excelURL : "https://app.pays.net.in/",
   ImageURL : "https://app.pays.net.in",
   btoaUsername:"pays",
   btoaPassword:"pays!@#$%^",
   companyid : 37,
   flag: null

  // baseURL: 'https://dev.pays.net.in:8444/Pays/',
  // excelURL : "https://dev.pays.net.in/",
  // ImageURL : "https://dev.pays.net.in",
  // btoaUsername:"pays",
  // btoaPassword:"pays!@#$%^",
  // companyid : 17,
  // flag: null 


};


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
